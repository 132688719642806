@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

:root {
  --background: white;
  --text-primary: rgb(60, 60, 60);
  --text-secondary: rgb(120, 120, 120);
  --accent: rgb(214, 241, 236);
}
[data-theme="dark"] {
  --background: rgb(30, 30, 30);
  --text-primary: white;
  --text-secondary: rgb(200, 200, 200);
  --accent: rgb(42, 63, 58);
}


.main{color:var(--text-primary);background-color:var(--background);font-size:large;font-weight:bold;padding:20px;height:calc(100vh - 40px);transition:all .5s}.switcher{color:var(--text-primary);background-color:var(--background);float:right;transition:all .5s;outline:none;border:none}.icon{color:var(--text-primary)}.home-button{float:right}.header{padding-bottom:48px}.mid-section{max-width:600px;width:80%;margin:0 auto;font-size:16px;font-weight:400;line-height:24px}.links-section{justify-content:space-between}.section{display:flex}.section-title{flex-grow:0;min-width:40px;padding-right:32px;color:var(--text-secondary)}.section-text{flex-grow:1}.link{color:inherit;padding-right:12px}.styled-link{color:inherit;text-decoration:none;background-color:var(--accent)}.butler:hover{transition:400ms;background-color:#9216c4;color:#fff}.undisclosed:hover{transition:400ms;background-color:#16258a;color:#fff}.microsoft:hover{transition:400ms;background-color:#1649a8;color:#fff}.goldmansachs:hover{transition:400ms;background-color:#2595d6;color:#fff}.mercari:hover{transition:400ms;background-color:rgba(214,27,27,.877);color:#fff}.rbc:hover{transition:400ms;background-color:#2239bb;color:#fff}.waterloo:hover{transition:400ms;background-color:#a59b16;color:#fff}.music:hover{transition:400ms;background-color:#d86609;color:#fff}

