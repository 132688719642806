.main {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  padding: 20px;
  height: calc(100vh - 40px);
  transition: all 0.5s;
}

.switcher {
  color: var(--text-primary);
  background-color: var(--background);
  float: right;
  transition: all 0.5s;
  outline: none;
  border: none;
}

.icon {
  color: var(--text-primary);
}

.home-button {
  float: right;
}

.header {
  padding-bottom: 48px;
}

.mid-section {
  max-width: 600px;
  width: 80%;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.links-section {
  justify-content: space-between;
}

.section {
  display: flex;
}

.section-title {
  flex-grow: 0;
  min-width: 40px;
  padding-right: 32px;
  color: var(--text-secondary);
}

.section-text {
  flex-grow: 1;
}

.link {
  color: inherit;
  padding-right: 12px;
}

.styled-link {
  color: inherit;
  text-decoration: none;
  background-color: var(--accent);
}

.butler:hover {
  transition: 400ms;
  background-color: rgb(146, 22, 196);
  color: white;
}

.undisclosed:hover {
  transition: 400ms;
  background-color: rgb(22, 37, 138);
  color: white;
}

.microsoft:hover {
  transition: 400ms;
  background-color: rgb(22, 73, 168);
  color: white;
}

.goldmansachs:hover {
  transition: 400ms;
  background-color: rgb(37, 149, 214);
  color: white;
}

.mercari:hover {
  transition: 400ms;
  background-color: rgba(214, 27, 27, 0.877);
  color: white;
}

.rbc:hover {
  transition: 400ms;
  background-color: rgb(34, 57, 187);
  color: white;
}

.waterloo:hover {
  transition: 400ms;
  background-color: rgb(165, 155, 22);
  color: white;
}

.music:hover {
  transition: 400ms;
  background-color: rgb(216, 102, 9);
  color: white;
}
